import Vue from 'vue'
import { getToken } from '@/util/auth'
import store from '../store'
import VueRouter from 'vue-router'
import PageRouter from './page/'
import ViewsRouter from './views/'
import vueRouter from './vue-router'

import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
NProgress.configure({ showSpinner: false })

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  routes: []
})

// const router = new VueRouter({
//   mode: 'hash', // 使用 hash 模式
//   routes: [
//     // 路由配置
//   ]
// })

vueRouter.install(Vue, router, store)
router.$vueRouter.formatRoutes(store.state.user.menu, true)
router.addRoutes([...PageRouter, ...ViewsRouter])

const whiteList = ['/', '/login', '/health/index', '/jumpPage', '/jumpPage%E6%89%93%E5%BC%80%E6%9F%A5%E7%9C%8B', '/areaPage', '/QRCode', '/AIIntelligentAgent'] // 不重定向白名单

router.beforeEach((to, from, next) => {
  const token = getToken()
  console.log('test token')
  console.log(token)
  console.log(store.getters.token)
  if (token) {
    console.log('ces111')
    // 如果已经登录，则直接跳转到首页
    if (to.path === '/login') {
      next('/selectInstitution')
    }
    if (to.path === '/health/index') {
      next()
    }
    // 如果用户信息为空则获取用户信息，获取用户信息失败，跳转到登录页
    if (store.getters.token.length === 0) {
      next({ path: '/login' })
    } else {
      // 否则放行
      next()
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next('/login')
      NProgress.done()
    }
  }
})

// if (whiteList.indexOf('/areaPage') !== -1) {
//   next()
// }

router.afterEach((to, from) => {
  console.log('test router.afterEach', to)
  NProgress.done()
  const title = to.query.name || to.name
  // 根据当前的标签也获取label的值动态设置浏览器标题
  router.$vueRouter.setTitle(title)
})

export default router
