export default [
  {
    path: '/login',
    name: '登录页',
    component: () => import('@/page/login/index'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/jumpPage',
    name: '跳转页',
    component: () => import('@/page/login/jumpPage'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/QRCode',
    name: '二维码页',
    component: () => import('@/page/login/QRCode'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/selectInstitution',
    name: '大白主动健康',
    component: () => import('@/page/login/selectInstitution'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/areaPage',
    name: '大白主动健康',
    component: () => import('@/page/login/areaPage'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/AIIntelligentAgent',
    name: '大白AI智能体',
    component: () => import('@/page/login/AIIntelligentAgent'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/S7vVAPcmbu.txt',
    name: '验证',
    component: () => import('@/page/login/S7vVAPcmbu.txt'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/jumpPage%E6%89%93%E5%BC%80%E6%9F%A5%E7%9C%8B',
    name: '跳转页',
    component: () => import('@/page/login/jumpPage'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/404',
    component: () => import('@/components/error-page/404'),
    name: '404',
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/403',
    component: () => import('@/components/error-page/403'),
    name: '403',
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/500',
    component: () => import('@/components/error-page/500'),
    name: '500',
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  }
  // ,
  // {
  //   path: '*',
  //   redirect: '/404'
  // }
]
