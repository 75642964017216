<!-- <script src="http://res.wx.qq.com/open/js/jweixin-1.6.0.js"></script>
<template>
  <div>
    <img src="https://health-planet.oss-cn-shanghai.aliyuncs.com/report/2024-08-20/f9a3275a53abc34fb5283436d25f79a068b1.png" class="wximg" />
  </div>
</template> -->

<!-- 以下代码请放入前端html文件的body内 -->
<!-- <body>
  <script src="https://agi-dev-platform-web.bj.bcebos.com/ai_apaas/embed/output/embedFullSDK.js?responseExpires=0"></script>
  <script>
      new EmbedWebSDK({appId: '16816eaa-289a-4f5c-9668-390a746a4fcb', code: 'embedfY6PmSLQdsVmtW3RnCBQ', renderConfig: {width: '100%', height: '100%'}});
  </script>
</body> -->

<template height="100%">
  <div class="view1">
    <!-- <img src="https://health-planet.oss-cn-shanghai.aliyuncs.com/report/2024-08-20/f9a3275a53abc34fb5283436d25f79a068b1.png" class="wximg" /> -->
    <iframe src="https://wx.baeapps.com/api/ai_apaas/v1/web_embed/html?app_id=16816eaa-289a-4f5c-9668-390a746a4fcb&code=embedfY6PmSLQdsVmtW3RnCBQ" allow="camera;microphone" width="100%" height="100%"></iframe>
  </div>
</template>
<!-- <script src="https://agi-dev-platform-web.bj.bcebos.com/ai_apaas/embed/output/embedFullSDK.js?responseExpires=0"></script>
<script>
    new EmbedWebSDK({appId: '16816eaa-289a-4f5c-9668-390a746a4fcb', code: 'embedfY6PmSLQdsVmtW3RnCBQ', renderConfig: {width: '100%', height: '100%'}});
</script> -->
<script>
// import {
//   getList
// } from '@/api/health/test'
// import { request } from '@/util/http'

export default {
  data () {
    return {
      requestHeadUrl: 'https://dbadmin.dbzdjk.com',
      appLink: ''
    }
  },
  created () {
    // const thisObj = this
    // thisObj.onLoad()
  },
  methods: {
    // onLoad: function () {
    //   const thisObj = this
    // },
  }
}
</script>

<style>
.view1{
  width: 100%;
  height: 100%;
}
.btn {
    padding: 12px;
    width: 200px;
    height: 50px;
}
.wximg {
  position: fixed;
  width: 20%;
  left: 40%;
  top: 25%;
}
.gotext {
  position: fixed;
  bottom: 20%;
  left: 30%;
  width: 40%;
  height: 80px;
  background: rgb(40, 196, 69);
  color: #ffffff;
  text-align: center;
  font-size: 35px;
  padding: 15px;
  border-radius: 15px;
}
</style>
